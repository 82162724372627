import React from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';
import styled from 'styled-components';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Title from '~/components/Title';
import Image2 from '~/assets/img/banner_sobre_2.jpeg';
import Image3 from '~/assets/img/banner_sobre_3.jpeg';
import Image4 from '~/assets/img/banner_sobre_4.jpeg';
import Team from '~/components/Team';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '420px'
  },
}));

const Description = withStyles((theme) => ({
  root: {
    color: '#142525',
    fontSize: '22px',
    lineHeight: '1.82',
    fontWeight: 'normal',
    marginTop: '48px',
    marginBottom: '70px',
    whiteSpace: 'pre-wrap'
  },
}))(Typography);

const DivImage = styled.div`
  padding: 0 15px;
  width: 100%;
  height: 420px;
  display: flex;
  border-radius: 8px;
  img {
    border-radius: 8px;
    object-fit: cover;
  }
`;

const Highlight = styled.div`
  width: 100%;
  min-height: 80px;
  border-radius: 40px;
  background-color: #496e6d;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.67;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  margin-bottom: 120px;
`;

function About() {
  const classes = useStyles();

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => {
      return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />;
    }
  };

  return <div className={classes.root}>
    <Grid container>
      <Grid item xs={12}>
        <Slider {...settings} >
          {
            [Image2, Image3, Image4].map((image, key) => {
              return <DivImage key={key}>
                <img src={image} alt="Neuron Clínica" width="100%" height="100%" />

              </DivImage>
            })
          }
        </Slider>
      </Grid>
    </Grid>

    <Grid container direction="row" justify="flex-end" alignItems="center">
      <Grid item xs={1} />
      <Grid item xs={10} style={{ marginTop: '80px' }}>
        <Title>Quem somos</Title>
        <Description component="div">
          <p>Fundada em 2004, a Neuron é uma clínica multidisciplinar de atendimento em várias subespecialidades Neurológicas e Neurocirúrgicas. O seu corpo clínico é composto por profissionais renomados, constantemente atualizados, e que atuam na formação acadêmica de novos especialistas da área em Pernambuco. Sempre com a missão de cuidar com excelência, acolhimento e humanização.</p>
        </Description>

        <Highlight>Sempre com a missão de cuidar com excelência, acolhimento e humanização.</Highlight>
      </Grid>
      <Grid item xs={1} />
    </Grid>

    <Team parent="about" />

    <Grid container direction="row" justify="flex-end" alignItems="center">
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Title>Planos de Saúde</Title>
        <Description>Entre em contato para se informar se o seu médico aceita o seu plano de sáude.</Description>
      </Grid>
      <Grid item xs={1} />
    </Grid>

  </div>
}

export default About;