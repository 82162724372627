import React from 'react';

import styled from 'styled-components';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Title from '~/components/Title';
import Img1170x420 from '~/assets/img/banner_especialidades.jpg';
import Icon from '~/components/Specialties/Icon';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '420px'
  },
}));

const Description = withStyles((theme) => ({
  root: {
    color: '#142525',
    fontSize: '22px',
    lineHeight: '1.82',
    fontWeight: 'normal',
    marginTop: '48px'
  },
}))(Typography);

const Specialty = withStyles((theme) => ({
  root: {
    color: '#324b4a',
    fontSize: '18px',
    fontWeight: 'bold',
  },
}))(Typography);

const SpecialtyDescription = withStyles((theme) => ({
  root: {
    color: '#142525',
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: '1.78',
    marginTop: theme.spacing(2)
  },
}))(Typography);

const DivImage = styled.div`
  padding: 0 15px;
  width: 100%;
  height: 420px;
  display: flex;
  border-radius: 8px;
  img {
    border-radius: 8px;
    object-fit: cover;
  }
`;

function About() {
  const classes = useStyles();

  return <div className={classes.root}>
    <Grid container>
      <Grid item xs={12}>
        <DivImage>
          <img src={Img1170x420} alt="" width="100%" height="100%" />
        </DivImage>
      </Grid>
    </Grid>

    <Grid container direction="row" justify="flex-end" alignItems="center">
      <Grid item xs={1} />
      <Grid item xs={10} style={{ marginTop: '80px', marginBottom: '80px' }}>
        <Title>Especialidades</Title>
        <Description>Conte com o atendimento profissional, dedicado e especializado da nossa equipe.</Description>
        <Grid container direction="column" justify="flex-start">
          <Grid item xs={12} style={{ marginTop: '64px' }}>
            <Grid container direction="row" alignItems="center">
              <Icon type="neurocirurgia" />
              <Specialty component="div" style={{ marginRight: '20px' }}>Neurocirurgia</Specialty>
            </Grid>
            <Grid container>
              <SpecialtyDescription>Especialidade dirigida ao tratamento de adultos e crianças portadores de doenças do sistema nervoso central e periférico.</SpecialtyDescription>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} style={{ marginTop: '64px' }}>
            <Grid container direction="row" alignItems="center">
              <Icon type="dor_orofacial" />
              <Specialty component="div" style={{ float: 'right' }}>Dor Orofacial</Specialty>
            </Grid>
            <Grid container>
              <SpecialtyDescription>Especialidade voltada para dores associadas à região da cabeça, face, pescoço e estruturas da cavidade oral.</SpecialtyDescription>
            </Grid>
          </Grid> */}
          <Grid item xs={12} style={{ marginTop: '64px' }}>
            <Grid container direction="row" alignItems="center">
              <Icon type="neurologia" />
              <Specialty component="div" style={{ float: 'right' }}>Neurologia</Specialty>
            </Grid>
            <Grid container>
              <SpecialtyDescription>Especialidade que se dedica ao diagnóstico e tratamento das doenças que afetam o sistema nervoso (cérebro, tronco encefálico, cerebelo, medula espinhal e nervos) e os componentes da  junção neuromuscular (nervo e músculos).</SpecialtyDescription>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container direction="column" justify="flex-start" style={{marginTop: '120px'}} id="t">
          <Title>Tratamentos</Title>
            <Ul>
              <li>Nam et aliquet leo. Cras interdum, tellus ut scelerisque lacinia, neque tortor hendrerit est.</li>
              <li>Nam et aliquet leo. Cras interdum, tellus ut scelerisque lacinia, neque tortor hendrerit est.</li>
              <li>Nam et aliquet leo. Cras interdum, tellus ut scelerisque lacinia, neque tortor hendrerit est.</li>
              <li>Nam et aliquet leo. Cras interdum, tellus ut scelerisque lacinia, neque tortor hendrerit est.</li>
              <li>Nam et aliquet leo. Cras interdum, tellus ut scelerisque lacinia, neque tortor hendrerit est.</li>
              <li>Nam et aliquet leo. Cras interdum, tellus ut scelerisque lacinia, neque tortor hendrerit est.</li>
            </Ul>
        </Grid>   */}
      </Grid>
      <Grid item xs={1} />
    </Grid>
  </div>
}

export default About;