import React, { useEffect } from 'react';

import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';

import Button from '~/components/Button';
import DoctorName from '~/components/Team/DoctorName';
import SpecialtyName from '~/components/Team/SpecialtyName';
import Popover from '~/components/Team/Popover';
import Image from '~/components/Team/Image';
import Mobile from '~/components/Team/Mobile';
import { fetchDoctorsAbout } from '~/store/modules/doctors/actions';
import { BASE_URL } from '~/shared/api';
import { slugify } from '~/shared/utils';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: '49px'
  },
  marginBottom: {
    marginBottom: '80px'
  },
  centerBox: {
    left: 'calc(50% - (152px / 2))'
  },
}));

export default function Web(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const specialties = useSelector(state => state.doctors.itemsAbout);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverData, setPopoverData] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(fetchDoctorsAbout());
  }, []);

  const togglePopover = () => {
    setOpen(!open);
  };

  const handleClick = (event, doctor) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen(!open);
    setPopoverData(doctor);
  };

  const onSwipe = () => {
    setAnchorEl(null);
    setOpen(false);
    setPopoverData();
  };

  return <React.Fragment>
    <Popover
      target={anchorEl}
      toggle={togglePopover}
      open={open}
      doctor={popoverData}
    />

    <Hidden mdUp>
      {
        !specialties.length ? null :
          specialties.map(specialty => {
            return !!specialty.doctors && specialty.doctors.length > 0 ? [
              <Grid container>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <SpecialtyName>{specialty.name}</SpecialtyName>
                </Grid>
              </Grid>,
              <Mobile
                parent="about"
                handleClick={handleClick}
                onSwipe={onSwipe}
                doctors={specialty.doctors}
              />
            ] : [];
          })
      }
    </Hidden>

    <Hidden smDown>
      <Grid container id="equipe" direction="row" justfy="center">
        {
          !specialties.length ? null :
            specialties.map((specialty, key) => {

              if (specialty.doctors && specialty.doctors.length === 0) {
                return []
              }

              let items = _.chunk(specialty.doctors, 5);

              let doctors = items.map((arr, key) => {
                return <Grid container key={key}>
                  <Grid item md={1} />
                  {arr.map((doctor) => {
                    return <Grid item md={2} key={doctor.id}>
                      <Box className={classes.centerBox}>
                        <Image src={`${BASE_URL}/upload/doctors/image/${doctor.image_dir}/${doctor.image}`} />
                      </Box>
                      <DoctorName style={{ marginBottom: props.parent === 'about' ? 0 : '16px' }}>{doctor.name}</DoctorName>
                      {props.parent === 'about' ? <Button type="team" onClick={(event) => handleClick(event, doctor)}>+</Button> : null}
                    </Grid>
                  })}
                  <Grid item md={1} />
                </Grid>;
              });

              return <Grid container className={classes.marginBottom} key={key} id={slugify(specialty.name)}>
                <Grid item md={1} />
                <Grid item md={11}>
                  <SpecialtyName>{specialty.name}</SpecialtyName>
                </Grid>
                <Grid container>
                  {doctors}
                </Grid>
              </Grid>;
            })
        }
      </Grid>
    </Hidden>
  </React.Fragment>
}