import React from 'react';

import styled from 'styled-components';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Form from '~/components/Form';
import PhoneMask from '~/components/Mask/Phone';
import Loading from '~/components/Loading';
import { sendContact } from '~/store/modules/contact/actions';
import Title from '~/components/Title';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const DivMap = styled.div`
  padding: 0 15px;
  width: 100%;
  height: 420px;
  display: flex;
  border-radius: 8px;
  img {
    border-radius: 8px;
    object-fit: cover;
  }
`;

const Description = withStyles((theme) => ({
  root: {
    color: '#142525',
    fontSize: '22px',
    lineHeight: '1.82',
    fontWeight: 'normal',
    marginTop: '48px',
    whiteSpace: 'pre-wrap'
  },
}))(Typography);


const schema = Yup.object().shape({
  name: Yup.string()
    .required('O nome é obrigatório'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  phone: Yup.string()
    .required('O telefone é obrigatório'),
  subject: Yup.string()
    .required('O assunto é obrigatório'),
  message: Yup.string()
    .required('A mensagem é obrigatório'),
});

const fields = [
  {
    id: 'name',
    name: 'name',
    label: 'Name',
  },
  {
    id: 'email',
    name: 'email',
    label: 'Email',
  },
  {
    id: 'phone',
    name: 'phone',
    label: 'Phone',
    inputComponent: PhoneMask,
  },
  {
    id: 'subject',
    name: 'subject',
    label: 'Assunto',
  },
  {
    id: 'message',
    name: 'message',
    label: 'Mensagem',
  },
];


function Contact() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);

  const onSubmit = (data) => {
    dispatch(sendContact(data));
  }

  return (
    <div className={classes.root}>
      <Loading show={loading} />
      <Grid container>
        <Grid item xs={12}>
          <DivMap>
            <iframe onLoad={() => setLoading(false)} title="googleMaps" style={{ borderRadius: '8px' }} width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=rua%20das%20fronteiras,%20127,%20boa%20bista+(Cl%C3%ADnica%20Neuro)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
          </DivMap>
        </Grid>
        <Grid item xs={1} />

        <Grid item xs={10}>
          <Description component="div">
            <p>Para entrar em contato ou fazer agendamento de consulta, preencha os formulários abaixo.</p>
          </Description>
        </Grid>
        <Grid item xs={1} />

        <Grid item xs={12}>

          <Form
            type="contact"
            title="Contato"
            schema={schema}
            fields={fields}
            onSubmit={onSubmit}
            initialValues={{ name: '', email: '', phone: '', subject: '', message: '' }}
            submitButtonLabel="ENVIAR"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default React.memo(Contact);