import React from 'react';

import styled from 'styled-components';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Img570x410 from '~/assets/img/banner_homeSobre.jpg';
import Title from '~/components/Title';
import Button from '~/components/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(10)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '420px'
  },
  margin: {
    marginTop: '73px',
    marginBottom: '73px',
  }
}));

const Text = withStyles((theme) => ({
  root: {
    color: '#142525',
    fontSize: '18px',
    lineHeight: '1.44',
    fontWeight: 'normal'
  },
}))(Typography);

const DivImage = styled.div`
  background-color: #F8F7EE; 
  max-width: 570px;
  height: 410px;
  border: 7px solid #F8F7EE;
  border-radius: 8px;

  img {
    object-fit: cover;
    border-radius: 8px;
  }
`;

export default function CenteredGrid() {
  const classes = useStyles();
  const history = useHistory();
  const text = "Fundada em 2004, a Neuron é uma clínica multidisciplinar de atendimento em várias subespecialidades Neurológicas e Neurocirúrgicas.";

  return (
    <div className={clsx(classes.root)}>
      <Grid container>
        <Grid container item direction="row" justify="center" alignItems="center" xs={12} sm={12} md={7}>
          <Grid item>
            <Box
              mt={10}
              ml={{ xs: 1, sm: 1, md: 14 }}
              mr={{ xs: 1, sm: 1, md: 5 }}
              maxWidth="570px">
              <DivImage>
                <img src={Img570x410} alt="" width="100%" height="100%" />
              </DivImage>
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={12} md={5}>
          <Grid item>
            <Box
              mt={{ xs: 5, md: 15 }}
              ml={{ xs: 5, md: 11 }}
              mr={{ xs: 5, md: 14 }}>
              <Title>Quem somos</Title>
              <Text style={{ marginTop: '32px', marginBottom: '40px' }} component="div">{text.substr(0, 166)}</Text>
              <Button onClick={() => history.push('/sobre')}>CONTINUAR LENDO</Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
