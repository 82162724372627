import React from 'react';

import { NavHashLink as Link } from 'react-router-hash-link';
import styled from 'styled-components';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Title from '~/components/Title';
import Icon from '~/components/Specialties/Icon';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '431px',
    height: 'auto',
    flexGrow: 1,
    backgroundColor: '#f8f7ee'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
    height: '420px'
  },
}));

const A = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpecialtiesText = withStyles((theme) => ({
  root: {
    color: '#324b4a',
    fontSize: '18px',
    lineHeight: '1.56',
    fontWeight: '300',
    marginTop: '32px'
  },
}))(Typography);

const SpecialtiesNames = withStyles((theme) => ({
  root: {
    color: '#324b4a',
    fontSize: '16px',
    fontWeight: 'normal',
  },
}))(Typography);

export default function CenteredGrid() {
  const classes = useStyles();

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid item xs={2} />
        <Grid item xs={8} style={{ marginTop: '80px' }}>
          <Title>Especialidades</Title>
          <SpecialtiesText component="div">Conte com o atendimento profissional, dedicado e especializado da nossa equipe.</SpecialtiesText>

          <Grid container direction="row" alignItems="center" style={{ marginTop: '40px' }}>
            <Grid item xs={12} md={4} style={{ marginBottom: '50px' }}>
              <Grid container direction="row" alignItems="center">
                <A to="/sobre#neurocirurgia" scroll={el => scrollWithOffset(el, 150)}>
                  <Icon type="neurocirurgia" />
                  <SpecialtiesNames component="div" style={{ marginRight: '20px' }}>Neurocirurgia</SpecialtiesNames>
                </A>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} md={4} style={{marginBottom: '50px'}}>
              <Grid container direction="row" alignItems="center">
                <A to="/sobre#dor-orofacial" scroll={el => scrollWithOffset(el, 150)}>
                  <Icon type="dor_orofacial" />
                  <SpecialtiesNames component="div" style={{float: 'right'}}>Dor Orofacial</SpecialtiesNames>
                </A>
              </Grid>
            </Grid> */}
            <Grid item xs={12} md={4} style={{ marginBottom: '50px' }}>
              <Grid container direction="row" alignItems="center">
                <A to="/sobre#neurologia" scroll={el => scrollWithOffset(el, 150)}>
                  <Icon type="neurologia" />
                  <SpecialtiesNames component="div" style={{ float: 'right' }}>Neurologia</SpecialtiesNames>
                </A>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </div>
  );
}
